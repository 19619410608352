exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-common-problems-in-db-design-lifecycle-js": () => import("./../../../src/pages/blog/common-problems-in-db-design-lifecycle.js" /* webpackChunkName: "component---src-pages-blog-common-problems-in-db-design-lifecycle-js" */),
  "component---src-pages-blog-issues-with-homomorphic-encryption-js": () => import("./../../../src/pages/blog/issues-with-homomorphic-encryption.js" /* webpackChunkName: "component---src-pages-blog-issues-with-homomorphic-encryption-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-main-subsystems-comprising-every-dbms-js": () => import("./../../../src/pages/blog/main-subsystems-comprising-every-dbms.js" /* webpackChunkName: "component---src-pages-blog-main-subsystems-comprising-every-dbms-js" */),
  "component---src-pages-blog-most-used-resource-management-solutions-js": () => import("./../../../src/pages/blog/most-used-resource-management-solutions.js" /* webpackChunkName: "component---src-pages-blog-most-used-resource-management-solutions-js" */),
  "component---src-pages-blog-problems-with-mlops-js": () => import("./../../../src/pages/blog/problems-with-mlops.js" /* webpackChunkName: "component---src-pages-blog-problems-with-mlops-js" */),
  "component---src-pages-blog-security-top-5-most-popular-data-search-systems-js": () => import("./../../../src/pages/blog/security-top-5-most-popular-data-search-systems.js" /* webpackChunkName: "component---src-pages-blog-security-top-5-most-popular-data-search-systems-js" */),
  "component---src-pages-blog-top-ml-execution-strategies-js": () => import("./../../../src/pages/blog/top-ml-execution-strategies.js" /* webpackChunkName: "component---src-pages-blog-top-ml-execution-strategies-js" */),
  "component---src-pages-blog-top-scenarios-where-serverless-computing-fails-js": () => import("./../../../src/pages/blog/top-scenarios-where-serverless-computing-fails.js" /* webpackChunkName: "component---src-pages-blog-top-scenarios-where-serverless-computing-fails-js" */),
  "component---src-pages-compliance-evaluator-js": () => import("./../../../src/pages/compliance-evaluator.js" /* webpackChunkName: "component---src-pages-compliance-evaluator-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domain-scanner-js": () => import("./../../../src/pages/domain-scanner.js" /* webpackChunkName: "component---src-pages-domain-scanner-js" */),
  "component---src-pages-fines-js": () => import("./../../../src/pages/fines.js" /* webpackChunkName: "component---src-pages-fines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-portal-custom-cyber-project-js": () => import("./../../../src/pages/portal/custom-cyber-project.js" /* webpackChunkName: "component---src-pages-portal-custom-cyber-project-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-portal-oss-case-management-js": () => import("./../../../src/pages/portal/oss-case-management.js" /* webpackChunkName: "component---src-pages-portal-oss-case-management-js" */),
  "component---src-pages-portal-oss-cti-js": () => import("./../../../src/pages/portal/oss-cti.js" /* webpackChunkName: "component---src-pages-portal-oss-cti-js" */),
  "component---src-pages-portal-oss-cyber-platform-js": () => import("./../../../src/pages/portal/oss-cyber-platform.js" /* webpackChunkName: "component---src-pages-portal-oss-cyber-platform-js" */),
  "component---src-pages-portal-oss-digital-forensics-js": () => import("./../../../src/pages/portal/oss-digital-forensics.js" /* webpackChunkName: "component---src-pages-portal-oss-digital-forensics-js" */),
  "component---src-pages-portal-oss-siem-js": () => import("./../../../src/pages/portal/oss-siem.js" /* webpackChunkName: "component---src-pages-portal-oss-siem-js" */),
  "component---src-pages-portal-oss-soar-js": () => import("./../../../src/pages/portal/oss-soar.js" /* webpackChunkName: "component---src-pages-portal-oss-soar-js" */),
  "component---src-pages-portal-oss-vulnerability-scanner-js": () => import("./../../../src/pages/portal/oss-vulnerability-scanner.js" /* webpackChunkName: "component---src-pages-portal-oss-vulnerability-scanner-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-success-stories-world-data-pro-js": () => import("./../../../src/pages/success-stories/world-data-pro.js" /* webpackChunkName: "component---src-pages-success-stories-world-data-pro-js" */)
}

